import moment from 'moment';
import {
  getMoney, getPercent, getNumber, copyObject,
} from '../../common/functions/helpers';
import { numberBetweenValues } from '../../common/functions/numeric';
import InputMoney from '../../shared-components/inputs/InputMoney';
import PlanejamentoContratoForm from '../planejamento-contrato/form/PlanejamentoContratoForm';
import ApuracaoContratoTabelaFornecedor from './ApuracaoContratoTabelaFornecedor';
import { cancelarApuracao } from '../../common/resources/apuracao/apuracao-acao-edicao';
import DialogJustificativa from '../../shared-components/vuetify/dialog/DialogJustificativa';
import ModalVisualizarPeriodoCalculado from './modals/ModalVisualizarPeriodoCalculado';
import DialogApuracaoContratoMetaIntervalo from './modals/DialogApuracaoContratoMetaIntervalo';
import DialogApuracaoContratoNdsGeradas from './modals/DialogApuracaoContratoNdsGeradas';
import DialogRealizadoDetalhamento from './modals/DialogRealizadoDetalhamento';
import DialogRealizadoDetalhamentoCategoriaAgrupada from './modals/DialogRealizadoDetalhamentoCategoriaAgrupada';
import DialogCategoriaNivelFamilia from './modals/DialogCategoriaNivelFamilia';
import DialogEditRecebimentoDefinido from './modals/DialogEditRecebimentoDefinido';
import DialogEditTipoUso from './modals/DialogEditTipoUso';
import DialogApuracaoContratoCancelamento from './modals/DialogApuracaoContratoCancelamento';
import DialogFinalizarApuracoes from './modals/DialogFinalizarApuracoes';
import {
  buscarDetalhesApuracaoContrato,
  buscarApuracaoFornecedorPorContrato,
} from '../../common/resources/apuracao/apuracao-contrato';
import {
  formatlastDayOfTheMonth,
} from '../../common/functions/date-utils';
import PopoverGenerico from '../../shared-components/PopoverGenerico';

export default {
  components: {
    PlanejamentoContratoForm,
    ApuracaoContratoTabelaFornecedor,
    DialogJustificativa,
    DialogApuracaoContratoMetaIntervalo,
    DialogApuracaoContratoNdsGeradas,
    DialogRealizadoDetalhamento,
    DialogRealizadoDetalhamentoCategoriaAgrupada,
    DialogCategoriaNivelFamilia,
    DialogEditRecebimentoDefinido,
    DialogEditTipoUso,
    DialogApuracaoContratoCancelamento,
    DialogFinalizarApuracoes,
    InputMoney,
    ModalVisualizarPeriodoCalculado,
    PopoverGenerico,
  },
  data() {
    return {
      workflowApuracaoAcao: this.$api.workflowApuracaoAcao(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      resourceApuracaoAcaoListagem: this.$api.apuracaoAcaoListagem(this.$resource),
      resourceContrato: this.$api.apuracaoContrato(this.$resource),
      panelKey: 0,
      totalPageApuracao: 0,
      esperar: false,
      recebimentoSemValorNaoEmitido: true,
      dialogJustificativaTipoUso: false,
      apuracaoSelecionada: {},
      modalJustificativaFlag: '',
      isModoEdicaoVlrFornecedor: false,
      prefixRefNameRecDefinido: 'modalRecDefinido-',
      prefixRefNameTipoUso: 'modalTipoUso-',
      dialogPlanejamentoContrato: false,
      idContrato: null,
      rules: {
        required: (value) => (!!value || value === 0) || this.$t('message.campo_obrigatorio'),
        valorAjusteNegativo: (value) => value >= 0 || this.$t('errors.valor_negativo'),
        valorPositivo: (value) => (!!value && value > 0) || this.$t('errors.valor.negativo'),
        valorMaiorQueEfetuado: (value, valorRecebido) => (!!value && value >= valorRecebido)
        || this.$t('errors.valor.menor_que_efetuado'),
      },
      headersApuracao: [
        { text: '', value: 'checkbox', sortable: false },
        { text: '', value: 'icon', sortable: false },
        {
          text: this.$tc('label.acao', 2), value: 'acoes', class: 'nowrap', sortable: false,
        },
        {
          text: this.$tc('label.cod_contrato', 1), value: 'idContrato', class: 'nowrap',
        },
        {
          text: this.$tc('label.fornecedor', 1), value: 'origemContrato', class: 'nowrap',
        },
        {
          text: this.$tc('label.cod_apuracao', 1), value: 'idApuracao', class: 'nowrap',
        },
        {
          text: this.$tc('title.categorias', 1), width: '25%', value: 'categoria', class: 'nowrap',
        },
        {
          text: '', value: 'iconCategoria', class: 'nowrap',
        },
        { text: this.$tc('label.periodo_calculado', 1), value: 'periodoVigencia' },
        {
          text: this.$tc('label.verba', 1), value: 'verba', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_total_nd_gerado', 1), value: 'vlrNdGerado', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.realizado', 1), width: '10%', value: 'vlrRealizado', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_base', 1), value: 'valorBase', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.ajuste_contratual', 1), width: '10%', value: 'vlrRetroativo', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.recebimento_definido', 1), value: 'recebimentoDefinido', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.tipo_uso_definido', 1), value: 'tipoUso', width: '10%', class: 'nowrap', cellClass: 'nowrap', sortable: false,
        },
        { text: '', value: 'data-table-expand' },
      ],
      apuracaoDetalhes: [],
      expanded: [],
      indJustificativa: false,
      metas: [],
      idsApuracaoDetalhes: [],
      exibeModalRecebimentoParcial: false,
      apuracaoParcial: {},
      tamanhoTela: null,
    };
  },
  methods: {
    getPercent,
    getMoney,
    getNumber,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    ordenacaoAlterada(p1, p2) {
      if ((p1.sortBy.length && !p2.sortBy.length) || (!p1.sortBy.length && p2.sortBy.length)) {
        return true;
      }
      if (p1.sortBy.length && p2.sortBy.length) {
        if (p1.sortBy[0] !== p2.sortBy[0]) {
          return true;
        }
        if (p1.sortBy[0] === p2.sortBy[0] && p1.sortDesc[0] !== p2.sortDesc[0]) {
          return true;
        }
      }
      return false;
    },
    onResize() {
      this.tamanhoTela = window.innerWidth;
    },
    statusAprovado(item) {
      return item.statusApuracao === 'APROVADO';
    },
    resetarPagination() {
      this.pagination.page = 1;
      this.resetarExpanded();
    },
    abrirModalIntervaloMeta(item) {
      const idAcao = Number(item.idAcao);
      const {
        calculoApuracao, formaBonificacao, faixaAtingida, existeAtingimento,
      } = { ...item };
      this.$refs.dialogMetaIntervalo.open(idAcao, calculoApuracao, formaBonificacao, faixaAtingida, existeAtingimento);
    },
    abrirModalCancelamento(item) {
      this.$refs.dialogCancelamento.open(item);
    },
    formatarVerba(item) {
      if (item.formaBonificacao === 'FIXO') {
        return this.getMoney(item.verba);
      }
      return this.getPercent(item.verba);
    },
    formatarVigencia(data) {
      return moment(data, 'DD/MM/YYYY').format('MMMM/YY').toUpperCase();
    },
    detalharApuracao(item) {
      this.dialogPlanejamentoContrato = true;
      this.idContrato = item.idContrato;
    },
    close() {
      this.dialogPlanejamentoContrato = false;
    },
    formatarValorRealizado(valor, calculoApuracao) {
      if (valor === 0) {
        return '-';
      }
      if (calculoApuracao === 'SELLIN_VALOR') {
        return this.getMoney(valor);
      }
      if (calculoApuracao === 'SELLIN_PERCENTUAL') {
        return this.getPercent(valor);
      }
      return this.getNumber(valor);
    },
    formatarValor(valor) {
      if (valor === 0) {
        return '-';
      }
      return this.getMoney(valor) || '-';
    },
    reRenderizarTable() {
      this.panelKey += 1;
    },
    indEdicaoRealizado(item) {
      return item.indEdicaoRealizado || item.indPreenchimentoValorRealizado;
    },
    indRecebimentoDefinidoAlterado(item) {
      return this.formatarValor(item.recebimentoDefinido) !== this.formatarValor(item.recebimentoAtual) && item.justificativaAlterarRecebimento;
    },
    indRealizadoDetalhamento(item) {
      return item.calculoApuracao === 'SELLIN_PERCENTUAL' && item.template === 'CRESCIMENTO';
    },
    indRealizadoDetalhamentoSellinValor(item) {
      return item.calculoApuracao === 'SELLIN_VALOR' && item.template === 'CRESCIMENTO' && item.vlrRealizado > 0;
    },
    realizadoPorQuantidade(item) {
      return (item.indPreenchimentoValorRealizado && item.template === 'META_N/A')
        || item.template === 'META_POR_QUANTIDADE';
    },
    abrirModalRealizado(idApuracao, indAcoesVerbasIguais) {
      this.$refs.dialogRealizadoDetalhamento.open(idApuracao, indAcoesVerbasIguais);
    },
    abrirModalRealizadoCategoriaAgrupada(idApuracao, dataInicio = null) {
      this.$refs.dialogRealizadoDetalhamentoCategoriaAgrupada.open(idApuracao, dataInicio);
    },
    abrirModalCategoriaNivelFamilia(idAcao) {
      this.$refs.dialogCategoriaNivelFamilia.open(idAcao);
    },
    indJustificativaAlterarValor(item) {
      return (item.indJustificativaAlterarValor && item.indJustificativa)
        || item.justificativaAlterarRecebimento;
    },
    cancelarAlteracaoValor() {
      const nomeComponente = 'valor_recebimento_'.concat(this.apuracaoSelecionada.idApuracao);
      this.retornarValoresIniciaisApuracao();
      this.$emit('desabilitaBotao', false);
      setTimeout(() => {
        const componente = document.getElementById(nomeComponente);
        if (componente !== null && componente !== undefined) {
          componente.focus();
        }
      });
    },
    confirmarAlteracaoValor(justificativa, apuracao = null) {
      this.$emit('desabilitaBotao', false);
      if (apuracao) {
        this.apuracaoSelecionada = apuracao;
        this.apuracaoParcial = apuracao;
      }
      const params = {
        justificativaAlteracaoValorPagamento: justificativa,
        vlrConfApuracao: parseFloat(this.apuracaoSelecionada.recebimentoDefinido),
        valorRealizado: this.apuracaoSelecionada.vlrRealizado,
      };

      this.atualizarValor(params);
    },
    buscarNdsGeradas(idApuracao) {
      this.resourceContrato.buscarNDsGeradas({ idApuracao }).then((res) => {
        if (res.body) {
          setTimeout(() => this.$refs.dialogNDsGeradas.open(res.body), 2E2);
        }
      });
    },
    alteracaoValorRealizado(item) {
      if (item.vlrRealizado === '' || item.vlrRealizado < 0
        || (item.vlrRealizado === item.valorRealizadoInicial)) {
        return;
      }
      this.apuracaoSelecionada = item;
      const params = {};
      params.valorRealizado = this.apuracaoSelecionada.vlrRealizado;
      if (this.apuracaoSelecionada.indPreenchimentoValorRealizado && this.apuracaoSelecionada.indAgrupamentoCategoriaKpi) {
        params.vlrConfApuracao = this.apuracaoSelecionada.vlrRealizado * this.apuracaoSelecionada.verba;
        if (params.vlrConfApuracao !== item.recebimentoDefinidoInicial) {
          this.apuracaoSelecionada.recebimentoDefinido = params.vlrConfApuracao;
          this.$refs.dialogJustificativa.open();
        } else {
          this.atualizarValor(params);
        }
      } else if (this.apuracaoSelecionada.indEdicaoRealizado) {
        this.buscarValorBonificacaoMeta(this.apuracaoSelecionada.vlrRealizado).then((valorBonificacao) => {
          if (this.apuracaoSelecionada.template === 'META_POR_QUANTIDADE') {
            if (this.apuracaoSelecionada.formaBonificacao === 'PERCENTUAL') {
              params.vlrConfApuracao = ((this.apuracaoSelecionada.vlrRealizado / 100) * valorBonificacao).toFixed(2);
            } else {
              params.vlrConfApuracao = valorBonificacao;
            }
          } else if (this.apuracaoSelecionada.formaBonificacao === 'PERCENTUAL') {
            params.vlrConfApuracao = ((this.apuracaoSelecionada.valorBase / 100) * valorBonificacao).toFixed(2);
            params.vlrConfApuracao = parseFloat(params.vlrConfApuracao);
          } else {
            params.vlrConfApuracao = valorBonificacao;
          }
          if (params.vlrConfApuracao !== item.recebimentoDefinidoInicial) {
            this.apuracaoSelecionada.recebimentoDefinido = params.vlrConfApuracao;
            this.$refs.dialogJustificativa.open();
          } else {
            this.atualizarValor(params);
          }
        });
      }
    },
    atualizarValor(params = {}) {
      const parametros = params;
      const datas = this.montarDataInicioFimEdicaoValores(this.apuracaoSelecionada);
      parametros.indApuracaoAgrupada = this.apuracaoSelecionada.indAgrupamentoCategoriaKpi;
      parametros.valorRealizadoOriginal = this.apuracaoSelecionada.recebimentoAtual;
      parametros.id = this.apuracaoSelecionada.idApuracao;
      parametros.idContrato = this.apuracaoSelecionada.idContrato;
      parametros.dtaInicio = datas.inicio ? datas.inicio : moment(this.filtros.dataInicio, 'YYYY-MM').format('YYYY-MM-DD');
      parametros.dtaFim = datas.fim ? datas.fim : formatlastDayOfTheMonth(moment(this.filtros.dataFim, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD');
      this.apuracaoAcaoResource.atualizar(parametros)
        .then(() => {
          const apuracao = this.apuracaoSelecionada;
          apuracao.valorRealizadoInicial = apuracao.vlrRealizado;
          this.buscarFornecedoresApuracao(apuracao.idApuracao).then(() => {
            apuracao.recebimentoDefinidoOriginal = params.vlrConfApuracao;
            apuracao.recebimentoDefinido = params.vlrConfApuracao;
            apuracao.justificativaAlterarRecebimento = params.justificativaAlteracaoValorPagamento;
            if (apuracao.valorRecebido) {
              apuracao.recebimentoPendente = apuracao.recebimentoDefinido - apuracao.valorRecebido;
            }
            this.apuracaoSelecionada = apuracao;
            this.apuracaoParcial = apuracao;
            this.indJustificativa = false;
            this.apuracaoSelecionada = {};
            this.$toast(this.$t('message.atualizar_tabela'));
            this.resetarExpanded();
            this.reRenderizarTable();
          });
        })
        .catch((err) => {
          this.$toast(err);
        });
    },
    guardarValorInicial(item) {
      item.valorRealizadoInicial = item.vlrRealizado;
      item.recebimentoDefinidoInicial = item.recebimentoDefinido;
    },
    buscarValorBonificacaoMeta(valor) {
      let valorBonificacao = 0;
      return this.resourceApuracaoAcaoListagem.buscarMetas({ idAcao: this.apuracaoSelecionada.idAcao }).then((res) => {
        this.metas.push(...res.data);
        if (Math.max(...this.metas.map((meta) => meta.vlrMetaAte)) < valor) {
          return Math.max(...this.metas.map((meta) => meta.vlrBonificacao));
        }
        const menorMeta = this.metas.find((meta) => meta.vlrMetaDe === 0);
        if (!!menorMeta && valor <= 0) {
          return menorMeta.vlrBonificacao;
        }
        this.metas.forEach((meta) => {
          if (numberBetweenValues(valor, meta.vlrMetaDe, meta.vlrMetaAte)) {
            valorBonificacao = meta.vlrBonificacao;
          }
        });
        return valorBonificacao;
      });
    },
    buscarFornecedoresApuracao(idApuracao, statusApuracao = null) {
      const filtrosAplicados = {
        cod_apuracao: idApuracao,
        data_inicio_apuracao: moment(this.filtros.dataInicio, 'YYYY-MM').format('YYYY-MM-DD'),
        data_fim_apuracao: formatlastDayOfTheMonth(moment(this.filtros.dataFim, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD'),
      };
      return buscarDetalhesApuracaoContrato({ ...filtrosAplicados }, this.$resource)
        .then((response) => {
          const apuracao = this.apuracaoSelecionada;
          apuracao.fornecedores = response.data;
          apuracao.fornecedores.forEach((e) => {
            e.statusApuracao = statusApuracao;
          });
          this.atualizarFornecedoresSelecionados(idApuracao, response.data);
          this.reRenderizarTable();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },

    atualizarFornecedoresSelecionados(idApuracao, fornecedores) {
      const copiaFornecedor = copyObject(fornecedores);
      this.apuracoes.forEach((a) => {
        if (a.idApuracao === idApuracao) {
          a.fornecedores = copiaFornecedor;
        }
      });
    },

    salvarValorAlterado(item) {
      this.apuracaoSelecionada = item;

      if (!this.indRecebimentoDefinidoAlterado(item)) {
        item.justificativaAlterarRecebimento = '';
      }
      const params = {
        idApuracao: item.idApuracao,
        vlrAnterior: parseFloat(item.recebimentoAtual),
        vlrAlterado: parseFloat(item.recebimentoDefinido),
        justificativa: item.justificativaAlterarRecebimento,
      };

      item.objRecebimentoDefinidoAlterado = params;

      this.indJustificativa = false;
      this.apuracaoSelecionada = {};

      this.$toast(this.$t('message.atualizar_tabela'));
      this.reRenderizarTable();
    },

    salvarTipoUsoIndividual(item) {
      this.apuracaoSelecionada = item;
      const { idApuracao } = item;
      this.workflowApuracaoAcao.salvarApuracaoAcaoContratoTipoUso({ idApuracao },
        {
          tipo_uso: item.idTipoUsoNovo,
          justificativa_tipo_uso: item.justificativaTipoUso,
        })
        .then(() => {
          this.$toast(this.$t('message.tipo_uso_alterado_sucesso'));
          const tipoUsoNovo = this.listaTiposUso.find((el) => el.id === this.apuracaoSelecionada.idTipoUsoNovo);
          this.apuracaoSelecionada.tipoUsoNovo = tipoUsoNovo.nome;
          this.resetarExpanded();
          this.reRenderizarTable();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    resetarExpanded() {
      this.expanded = [];
      this.idsApuracaoDetalhes = [];
    },
    verificarApuracaoExpandida(apuracoes) {
      if (!apuracoes.length) {
        return;
      }
      const idsNaoBuscados = apuracoes.filter((e) => !this.idsApuracaoDetalhes.includes(e.idApuracao));
      if (idsNaoBuscados && idsNaoBuscados.length) {
        const { idApuracao, statusApuracao } = idsNaoBuscados[0];
        if (idApuracao) {
          this.apuracaoSelecionada = apuracoes;
          this.idsApuracaoDetalhes.push(idApuracao);
          this.buscarFornecedoresApuracao(idApuracao, statusApuracao);
        }
      }
    },
    confirmarCancelarApuracao(item, justificativaCancelamento) {
      const apuracao = item;
      const { idApuracao } = apuracao;
      const param = {
        idApuracao,
        justificativa: justificativaCancelamento,
      };
      cancelarApuracao(param, this.$resource)
        .then(() => {
          const index = this.apuracoes.findIndex((a) => a.idApuracao === idApuracao);
          this.apuracoes.splice(index, 1);
          this.$toast(this.$t('message.apuracao_cancelada_sucesso'));
          this.apuracaoSelecionada = {};
          this.reRenderizarTable();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    visualizarPeriodoCalculado(apuracao) {
      setTimeout(() => this.$refs.ModalVisualizarPeriodoCalculado.open(apuracao), 2E2);
    },
    recarregarApuracao(apuracao) {
      const { idApuracao, periodicidade } = apuracao;
      const filtrosAplicados = {
        periodo_apuracao: periodicidade,
        cod_apuracao: idApuracao,
        data_inicio_apuracao: moment(this.filtros.dataInicio, 'YYYY-MM').format('YYYY-MM-DD'),
        data_fim_apuracao: formatlastDayOfTheMonth(moment(this.filtros.dataFim, 'YYYY-MM').format('MM-YYYY'), 'YYYY-MM-DD'),
      };
      buscarApuracaoFornecedorPorContrato(filtrosAplicados, this.$resource)
        .then((response) => {
          const apuracaoRetorno = response.data.resposta;
          this.apuracoes.forEach((a) => {
            if (a.idApuracao === idApuracao) {
              const { valorBase, recebimentoDefinido, indValorAlterado } = { ...apuracaoRetorno.at(0) };
              a.valorBase = valorBase;
              a.recebimentoDefinido = recebimentoDefinido;
              a.indValorAlterado = indValorAlterado;
            }
          });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
};
