<template>
  <div>
    <v-container pt-6 fluid v-resize="onResize">
      <v-row>
        <v-data-table
          :key="panelKey"
          item-key="idApuracao"
          :headers="headersApuracao"
          :items="apuracoes"
          :options.sync="pagination"
          :server-items-length="totalPage"
          show-expand
          :expanded.sync="expanded"
          class="elevation-0"
          :no-data-text="$t('label.tabela_sem_conteudo')"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50],
          }">
          <template v-slot:[`item.checkbox`]="{ item }">
            <v-checkbox
              v-model="item.selecionado"
              v-if="!statusAprovado(item)"
              hide-details
              class="shrink pt-0 mt-0"
              @click.stop="(e) => selecionarItem(item, e)">
            </v-checkbox>
          </template>
          <template v-slot:[`item.icon`]="{ item }">
            <v-tooltip bottom v-if="!statusAprovado(item)">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="orange darken-1"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  report_problem
                </v-icon>
              </template>
              <span>{{ $t('message.periodo_total_ou_parte_apurado') }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="statusAprovado(item)">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="deep-orange accent-4"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>{{ $t('label.periodo_apurado') }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.acoes`]="{ item }">
            <v-menu bottom
              origin="center center"
              transition="scale-transition">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :id="'more_vert_' + item.id"
                  icon>
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="detalharApuracao(item)">
                  <v-list-item-action>
                    <v-icon>details</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="!statusAprovado(item) && !item.vlrNdGerado"
                  @click="abrirModalCancelamento(item)">
                  <v-list-item-action>
                    <v-icon>cancel</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>{{ $tc('label.cancelar', 1) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:[`item.periodoVigencia`]="{ item }">
            <div>
            <span v-if="!statusAprovado(item) && item.dataParcialFormatada
              && item.dataParcialFormatada.resultado && !item.dataParcialFormatada.abrirModal">
              {{ item.dataParcialFormatada.resultado }}
            </span>
            <v-btn v-if="!statusAprovado(item) && item.dataParcialFormatada
              && item.dataParcialFormatada.resultado && item.dataParcialFormatada.abrirModal" icon class="mx-0"
              @click.native="visualizarPeriodoCalculado(item)">
              <v-icon>visibility</v-icon>
            </v-btn>
            </div>
          </template>
          <template v-slot:[`item.verba`]="{ item }">
            <tr v-if="item.indMetaIntervalo">
              <td>
                <span class="d-block">
                  {{ item.faixaAtingida ? item.formaBonificacao === 'FIXO' ?
                    getMoney(item.faixaAtingida) : getPercent(item.faixaAtingida) : $tc('label.sem_atingimento', 1)}}
                </span>
              </td>
              <td>
                <v-btn
                  icon
                  @click="abrirModalIntervaloMeta(item)"
                  class="ml-2 text-left"
                >
                  <v-icon>
                    add_box
                  </v-icon>
                </v-btn>
              </td>
            </tr>
            <span v-else>
              {{ formatarVerba(item) }}
            </span>
          </template>
          <template v-slot:[`item.vlrNdGerado`]="{ item }">
            <span v-if="item.vlrNdGerado">
              {{ getMoney(item.vlrNdGerado) }}
              <v-btn
                icon
                @click="buscarNdsGeradas(item.idApuracao)"
                class="mx-2"
              >
                <v-icon>
                  info
                </v-icon>
              </v-btn>
            </span>
            <span v-else>
              {{ getMoney(item.vlrNdGerado) }}
            </span>
          </template>
          <template v-slot:[`item.iconCategoria`]="{ item }">
            <v-container fluid v-if="item.existeFamiliaSelecionada">
              <v-row>
                <v-col cols="2" sm="2" class="pl-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click="abrirModalCategoriaNivelFamilia(item.idAcao)"
                      >
                      <v-icon
                      class="ml-3"
                      color="orange darken-1"
                      dark>
                        report_problem
                      </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $tc('label.detalhamento_familias_selecionadas', 1) }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.vlrRealizado`]="{ item }">
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="px-0" v-if="indEdicaoRealizado(item)" :sm="indRealizadoDetalhamento(item) ? 8 : 12">
                  <v-text-field
                    v-if="realizadoPorQuantidade(item)"
                    :id="`vlrRealizado-${item.idApuracao}`"
                    :ref="`vlrRealizado-${item.idApuracao}`"
                    v-model="item.vlrRealizado"
                    :rules="[rules.required, rules.valorAjusteNegativo]"
                    :disabled="statusAprovado(item)"
                    type="Number"
                    style="min-width:80px"
                    :hint="String(item.vlrRealizadoOriginal)"
                    @focus="guardarValorInicial(item)"
                    @change="alteracaoValorRealizado(item)"
                  ></v-text-field>
                 </v-col>
                 <v-col class="mt-2 pl-0" v-else cols="12" :sm="indRealizadoDetalhamento(item) ? 4 : 10">
                  <span v-if="indRealizadoDetalhamento(item) || indRealizadoDetalhamentoSellinValor(item)">
                    {{ !item.indValorRealizadoOriginalZerado ? formatarValorRealizado(item.vlrRealizado, item.calculoApuracao) : '-'}}
                  </span>
                  <span v-else>
                    {{'-'}}
                  </span>
                </v-col>
                <v-col cols="12" sm="4" class="px-0" v-if="indRealizadoDetalhamento(item)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click="abrirModalRealizado(item.idApuracao, item.indAcoesVerbasIguais)"
                        :class="indEdicaoRealizado(item) ? 'mx-2 mt-4' : ''"
                      >
                        <v-icon
                        color="orange darken-1"
                        dark>
                        report_problem
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $tc('label.detalhamento_realizado', 1) }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="2" sm="2" class="pr-0" v-else-if="!indEdicaoRealizado(item) && indRealizadoDetalhamentoSellinValor(item) && item.indAcoesVerbasIguais">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click="abrirModalRealizadoCategoriaAgrupada(item.idApuracao)"
                        :class="indEdicaoRealizado(item) ? 'mx-2 mt-4' : ''"
                      >
                      <v-icon
                      class="ml-3"
                      color="orange darken-1"
                      dark>
                        report_problem
                      </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $tc('label.detalhamento_realizado', 1) }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.valorBase`]="{ item }">
            <span v-if="item.formaBonificacao === 'FIXO'"> {{ $t('-') }} </span>
            <input-money
              :id="'id-valor-base-novo-nd' + item.id"
              v-else-if="item.indValorAlterado"
              v-model="item.valorBase"
              :hint="getMoney(item.valorBaseOriginal)"
              :disabled="true"
              :persistent-hint="true"
              class="v-text-field"
            ></input-money>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on }">
                <span>
                  {{ item.indAcoesVerbasIguais ? !item.indValorRealizadoOriginalZerado ? getMoney(item.valorBaseCategoriaOriginal) : '-' : getMoney(item.valorBase)}}
                  <span v-on="on" class="mb-1" v-if="item.valorBase !== item.valorBaseOriginal && !item.indAcoesVerbasIguais">
                    <popover-generico
                      icone="info"
                      label="label.valor_base_original"
                      :valor="item.valorBaseOriginal"
                      :is-valor-monetario="true"/>
                  </span>
                </span>
              </template>
              <span>{{ $tc('label.valor_base_original', 1) }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.vlrRetroativo`]="{ item }">
            {{ getMoney(item.vlrRetroativo) }}
            <tooltip-ajuda v-if="item.vlrRetroativo" color="black"
              :mensagem="`${$t('message.valor_retroatividade', { descricaoContratoAnterior: item.descricaoContratoAnterior})}`"
          />
          </template>
          <template v-slot:[`item.recebimentoDefinido`]="{ item }">
            <input-money
              :id="'id-valor-novo-nd' + item.id"
              v-if="item.indValorAlterado"
              v-model="item.recebimentoDefinido"
              :hint="getMoney(item.recebimentoAtual)"
              :disabled="true"
              :persistent-hint="true"
              class="v-text-field"
            ></input-money>
            <dialog-edit-recebimento-definido
              v-else
              ref="recDefinido"
              :item="item"
              :salvar-valor-alterado="salvarValorAlterado"
              :ind-recebimento-definido-alterado="indRecebimentoDefinidoAlterado"
            >
            </dialog-edit-recebimento-definido>
          </template>
          <template v-slot:[`item.tipoUso`]="{ item }">
            <dialog-edit-tipo-uso
              ref="tipoUso"
              :item="item"
              :lista-tipos-uso="listaTiposUso"
              :salvar-tipo-uso-individual="salvarTipoUsoIndividual"
            >
            </dialog-edit-tipo-uso>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <apuracao-contrato-tabela-fornecedor
                :apuracao="item"
                :dta-inicio="filtros.dataInicio"
                :dta-fim="filtros.dataFim"
                :finalizar-apuracao="finalizarApuracao"
                @RecarregarApuracao="recarregarApuracao"
              >
              </apuracao-contrato-tabela-fornecedor>
            </td>
          </template>
        </v-data-table>
      </v-row>
    </v-container>
    <dialog-justificativa
      ref="dialogJustificativa"
      :justificativa="apuracaoSelecionada.justificativaAlterarRecebimento"
      @confirmar="confirmarAlteracaoValor"
      @cancelar="cancelarAlteracaoValor">
    </dialog-justificativa>

    <dialog-realizado-detalhamento
      ref="dialogRealizadoDetalhamento">
    </dialog-realizado-detalhamento>

    <dialog-realizado-detalhamento-categoria-agrupada
      ref="dialogRealizadoDetalhamentoCategoriaAgrupada">
    </dialog-realizado-detalhamento-categoria-agrupada>

    <dialog-categoria-nivel-familia
      ref="dialogCategoriaNivelFamilia">
    </dialog-categoria-nivel-familia>

    <dialog-apuracao-contrato-meta-intervalo
      ref="dialogMetaIntervalo">
    </dialog-apuracao-contrato-meta-intervalo>

    <dialog-apuracao-contrato-cancelamento
      ref="dialogCancelamento"
      :confirmarCancelarApuracao="confirmarCancelarApuracao">
    </dialog-apuracao-contrato-cancelamento>

    <dialog-apuracao-contrato-nds-geradas
      ref="dialogNDsGeradas">
    </dialog-apuracao-contrato-nds-geradas>

    <modal-visualizar-periodo-calculado
    ref="ModalVisualizarPeriodoCalculado"
    :verificaRetornaDatasValidas="verificaRetornaDatasValidas"
    >
    </modal-visualizar-periodo-calculado>

    <v-dialog
      v-model="dialogPlanejamentoContrato"
      v-if="dialogPlanejamentoContrato"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition">
      <v-card class="ModalPlanejamentoContrato">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close()">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title><b>{{ $tc('title.planejamento_contrato', 1).toUpperCase()}}</b></v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <planejamento-contrato-form
            ref="modalForm"
            :somente-leitura="true"
            :id-contrato-fechamento="idContrato">
          </planejamento-contrato-form>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import ApuracaoContratoTabData from './ApuracaoContratoTabData';

export default {
  name: 'ApuracaoContratoTabSemestral',
  mixins: [
    ApuracaoContratoTabData,
  ],
  components: {
  },
  props: {
    apuracoes: {
      type: Array,
      default: () => ([]),
    },
    totalPage: {
      type: Number,
      default: 0,
    },
    verificaRetornaDatasValidas: {
      type: Function,
    },
    montarDataInicioFimEdicaoValores: {
      type: Function,
    },
    listaTiposUso: {
      type: Array,
      default: () => ([]),
    },
    filtros: {
      type: Object,
    },
    selecionarItem: Function,
    finalizarApuracao: Function,
  },
  data() {
    return {
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      lastPagination: {},
    };
  },
  computed: {
  },
  watch: {
    pagination: {
      handler(pagination) {
        if (this.lastPagination.page === null || typeof this.lastPagination.page === 'undefined') {
          this.lastPagination = pagination;
          return;
        }
        if (this.mesmaPagina(pagination, this.lastPagination) && !this.ordenacaoAlterada(pagination, this.lastPagination)) {
          return;
        }
        this.resetarExpanded();
        this.lastPagination = pagination;
        this.$emit('ApuracaoContratoTabSemestral__AplicaFiltros', this.pagination);
      },
      deep: true,
    },
    expanded(apuracao) {
      this.verificarApuracaoExpandida(apuracao);
    },
  },
  methods: {
  },
};
</script>
